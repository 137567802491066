import React, { useState, useEffect, useMemo } from 'react';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useSelector, useDispatch } from 'react-redux'
import { productsReorder } from '../Redux/ReOrdering/ReOrderingActions';
import { fetchproducts } from '../Redux/AllCategories/AllCategoriesActions';
import { Link, useHistory, useLocation } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';


function SingleCategoryList() {
  // store data access start
  const categoryData = useSelector(state => state.AllCategories)
  const reOrderingData = useSelector(state => state.ReOrdering)
  // store data access End
  const dispatch = useDispatch()  // for accessing the redux function
  const { state } = useLocation();
  const history = useHistory();
  // component all states define start
  const [allProductResult, setAllProductResult] = useState([]);
  const [allproductsloaded, setAllproductsloaded] = useState('')
  const [respmessage, setRespmessage] = useState('')
  const [editCategoryResp, setEditCategoryResp] = useState({
    type: '',
    message: ''
  })
  
  const [saveState, setSaveState] = useState(false)



  useMemo(() => {
    if (categoryData && categoryData.edit_category && saveState) {
      editCategoryResp.type = 'success'
      editCategoryResp.message = 'Updated Successfully!'
      setEditCategoryResp(editCategoryResp);
    }
  }, [categoryData])

  const resetEditCategory = () => {
    editCategoryResp.type = ''
    editCategoryResp.message = ''
    setEditCategoryResp(editCategoryResp);
  }


  // done edit part
  // const editProductClick = (item) => {  
  //   history.push({
  //      pathname: "/edit_product",
  //      state: editProduct(item)
  //   })
  // }

  const goToBack = () => {
      history.goBack();
  }

  const editProduct = (item,catID) => {
    let product = {
      catName: state.catName,
      catID: catID,
      productId: item.productId,
      name: item.name,
      description: item.descriptions,
      shortDescription: item.shortDescription,
      price: item.price,
      stockStatus: item.inStock
    };
    return product;
  }

  useMemo(() => {
    if (categoryData && categoryData.edit_category_error && categoryData.edit_category_error.object) {
      editCategoryResp.type = 'error'
      editCategoryResp.message = categoryData.edit_category_error.object.error
      setEditCategoryResp(editCategoryResp);
    }
  }, [categoryData])

  useMemo(() => {
    if (reOrderingData && reOrderingData.product_reorder && reOrderingData.product_reorder.object) {
      setRespmessage("reorder successfully!");
      
    }
  }, [reOrderingData]);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(allProductResult);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);

    setAllProductResult(reorderedItems);
    const productLst = reorderedItems.map(item => item.productId);
    dispatch(productsReorder(productLst));
  };

  useMemo(() => {
    if (categoryData && categoryData.all_products) {
      setAllproductsloaded(false);
      setAllProductResult(categoryData.all_products.data);
      if (categoryData.all_products_loaded) {
        setAllproductsloaded(true);
      }
    }
  }, [categoryData]);


      useEffect(() => {    
        dispatch(fetchproducts(state.catID));
      }, [dispatch])
  

  return (
    <>
      {/* Breadcubs Area Start Here */}
      <div className="breadcrumbs-area">
        <button className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark right-float" onClick={(e)=>goToBack()}>Back</button>
        <h3>Categories</h3>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link onClick={(e)=>goToBack()}>
            All Categories</Link>
          </li>
        <li>{state.catName}</li>
       
        </ul>
      </div>


      
             

{/* <div className="card-body2" style={{ marginBottom: '15px', overflowX: 'auto' }}> */}


<div className="row">
        <div className="col-12-xxxl col-12">
          <div className="card">


<div className="card-body2" style={{marginBottom: "15px", overflowX: "auto"}}>
  <div className="pad-10">
    <div className="float-left mg-t-5">
      <span><b>Category-Name:-</b>{state.catName}</span>
      <p><b>Category-Description:-</b>{state.catLongDes}</p>
      </div> 
 
        </div>
        </div>



          </div>
        </div>
      </div>
           
          


      <div className="row">
        <div className="col-12-xxxl col-12">
          <div className="card">

            <div className="card-body2" style={{ marginBottom: '15px', overflowX: 'auto' }}>

            {reOrderingData.is_loading ?
                          <div className='mg-l-40'>
                          <Loader
                            className = "cl-center"
                            type="MutatingDots"
                            color="#fea801"
                            height={150}
                            width={150}    
                          /></div>
               : reOrderingData.product_reorder && reOrderingData.product_reorder.object === true ? (<div className="success-greeting1">
                             <h2 className="addtimesuccess cl-center">{respmessage}</h2>                              
                          </div>) : reOrderingData.product_reorder && reOrderingData.product_reorder.object.error ? (<div className="success-greeting1">
                             <h2 className="addtimeerror cl-center">{reOrderingData.product_reorder.object.error}</h2>
                          
                           </div>): null}

            {!allproductsloaded ?
               <div style={{textAlign: 'center'}}>
                  <Loader
                    className="student-detail-loader"
                    type="ThreeDots"
                    color="#fea801"
                    height={150}
                    width={150}
                  />
                </div> :
               <><div style={{padding:'10px', color:'#000000'}}>Items:</div>
               <table className="table data-table text-nowrap">
                 <tbody><thead></thead>
                 {!allproductsloaded ?
               <div style={{textAlign: 'center'}}>
                  <Loader
                    className="student-detail-loader"
                    type="ThreeDots"
                    color="#fea801"
                    height={150}
                    width={150}
                  />
                </div>:
                
                <DragDropContext onDragEnd={handleOnDragEnd}>
  <Droppable droppableId="allProductResult">
    {(provided) => (
      <tr {...provided.droppableProps} ref={provided.innerRef} style={{
        width: '100%'
      }}>
        {allproductsloaded && allProductResult.length > 0 && 
          allProductResult.map((item, index) => (
            <Draggable key={item.productId} draggableId={item.productId} index={index}>
              {(provided) => (
                <tr
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={{
                    ...provided.draggableProps.style,
                    borderBottom: '1px solid #dee2e6',
                    width: '100%'
                  }}
                >
                                                                                                                          
                               
                                 <td style={{width:'100px'}} className='no-border'><i className="fas fa-grip-vertical"></i></td>
                                 <td style={{width:'700px'}} className='no-border'>{item.name}</td>
                                 <td style={{width:'150px'}} className='no-border'>$ {item.price}</td>
                                 {item.inStock ? <td  style={{width:'200px'}} className='no-border'>In Stock</td> : <td style={{width:'200px'}} className='no-border'>Out of Stock</td>}                                 
                                 <td style={{width:'100px'}} className='no-border'><i className="fas fa-ellipsis-v"></i></td>
                                                  
                            
                                 </tr> 
              )}
            </Draggable>
          ))
        }
        {provided.placeholder}
      </tr>
    )}
  </Droppable>
</DragDropContext>
                
                
                }
                   
                 </tbody>
               </table></> }
            </div>
          
          </div>
        </div>
      </div>



    </>

  );
}

export default SingleCategoryList;
