import {
  GET_OUTLET_REQUEST,
  GET_OUTLET_SUCCESS,
  GET_OUTLET_FALIURE,
  ENABLE_OUTLET_REQUEST,
  ENABLE_OUTLET_SUCCESS,
  ENABLE_OUTLET_FALIURE,
  DELETE_OUTLET_REQUEST,
  DELETE_OUTLET_SUCCESS,
  DELETE_OUTLET_FALIURE
} from './OutletConstants'
import {config} from '../config'

export const getOutletRequest = (outlet_req) =>{
  return{
    type : GET_OUTLET_REQUEST,
    payload : outlet_req
  }
}

export const getOutletSuccess = (outlet_success) =>{
  return{
    type : GET_OUTLET_SUCCESS,
    payload : outlet_success
  }
}

export const getOutletFaliure = (error) =>{
  return{
    type : GET_OUTLET_FALIURE,
    payload : error
  }
}

export const enableOutletRequest = (outlet_req) =>{
  return{
    type : ENABLE_OUTLET_REQUEST,
    payload : outlet_req
  }
}

export const enableOutletSuccess = (outlet_success) =>{
  return{
    type : ENABLE_OUTLET_SUCCESS,
    payload : outlet_success
  }
}

export const enableOutletFaliure = (error) =>{
  return{
    type : ENABLE_OUTLET_FALIURE,
    payload : error
  }
}

export const deleteOutletRequest = (outlet_req) =>{
  return{
    type : DELETE_OUTLET_REQUEST,
    payload : outlet_req
  }
}

export const deleteOutletSuccess = (outlet_success) =>{
  return{
    type : DELETE_OUTLET_SUCCESS,
    payload : outlet_success
  }
}

export const deleteOutletFaliure = (error) =>{
  return{
    type : DELETE_OUTLET_FALIURE,
    payload : error
  }
}

export const getOutletStatus = () =>{
  return(dispatch) => {
    dispatch(getOutletRequest(false))
    const url =`${config.api_root}/api/v1/merchants/outlet_status`
    const request_option = {
       method: "GET",
       headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
        }
    }
    fetch(url, request_option)
     .then(response => response.json() )
     .then(res =>{
        dispatch(getOutletSuccess(res))
     })
    .catch(error => {
      const errorMsg = error
      dispatch(getOutletFaliure(errorMsg))
    })
  }
}

export const enableOutlet = (field) =>{
  return(dispatch) => {
    dispatch(enableOutletRequest(false))
    const url =`${config.api_root}/api/v1/merchants/outlet`
    const request_option = {
       method: "POST",
       headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
       },
       body: JSON.stringify({
          user_id: config.user_id,
          fields: field
       })  
    }
    fetch(url, request_option)
     .then(response => response.json() )
     .then(res =>{
        dispatch(enableOutletSuccess(res))
     })
    .catch(error => {
      const errorMsg = error
      dispatch(enableOutletFaliure(errorMsg))
    })
  }
}

export const disableOutlet = (field) =>{
  return(dispatch) => {
    dispatch(deleteOutletRequest(false))
    const url =`${config.api_root}/api/v1/merchants/outlet`
    const request_option = {
       method: "DELETE",
       headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
       } 
    }
    fetch(url, request_option)
     .then(response => response.json() )
     .then(res =>{
        dispatch(deleteOutletSuccess(res))
     })
    .catch(error => {
      const errorMsg = error
      dispatch(deleteOutletFaliure(errorMsg))
    })
  }
}