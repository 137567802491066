import {
  Add_SPECIAL_DEALS_REQUEST,
  Add_SPECIAL_DEALS_SUCCESS,
  Add_SPECIAL_DEALS_FALIURE
} from './SpecialDealsConstants'
import {config} from '../config'

export const SpecialDealsRequest = (gallery_req) =>{
  return{
    type : Add_SPECIAL_DEALS_REQUEST,
    payload : gallery_req
  }
}

export const SpecialDealsSuccess = (gallery_req_success) =>{
  return{
    type : Add_SPECIAL_DEALS_SUCCESS,
    payload : gallery_req_success
  }
}

export const SpecialDealsFaliure = (error) =>{
  return{
    type : Add_SPECIAL_DEALS_FALIURE,
    payload : error
  }
}

export const addSpecialDeals = (specialDeals) =>{
  return(dispatch) => {
    dispatch(SpecialDealsRequest(false))
    const url =`${config.api_root}api/v1/merchants/categories`
    const request_option = {
       method: "POST",
       headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
       },
       body: JSON.stringify({
         user_id: config.user_id,
         fields: {
           categoryName: specialDeals.name,
           descriptions: specialDeals.description,
           type: specialDeals.type,
           available_online: specialDeals.available_online,
           visibilityConfigurations: specialDeals.configurations
         }
       })
    }
    fetch(url, request_option)
     .then(response => response.json() )
     .then(res =>{
        dispatch(SpecialDealsSuccess(res))
     })
    .catch(error => {
      const errorMsg = error
      dispatch(SpecialDealsFaliure(errorMsg))
    })
  }
}