import {
  Add_SPECIAL_DEALS_REQUEST,
  Add_SPECIAL_DEALS_SUCCESS,
  Add_SPECIAL_DEALS_FALIURE
} from './SpecialDealsConstants'

const intialstate = {
  is_loading : false,
  success_status : false,
  add_special_deals : null,
  get_error : ''
}

const SpecialDealsReducers = (state = intialstate,action) =>{
  switch(action.type){
    case Add_SPECIAL_DEALS_REQUEST : return{
      ...state,
      is_loading: true,
      success_status: false,
      add_special_deals: null
    }
    case Add_SPECIAL_DEALS_SUCCESS : return{
      ...state,
      is_loading: false,
      success_status: true,
      add_special_deals: action.payload
    }
    case Add_SPECIAL_DEALS_FALIURE : return{
      ...state,
      is_loading: false,
      success_status: false,
      get_error:action.payload
    }
    default: return state
  }

}

export default SpecialDealsReducers
