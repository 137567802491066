import {
  GET_OUTLET_REQUEST,
  GET_OUTLET_SUCCESS,
  GET_OUTLET_FALIURE,
  ENABLE_OUTLET_REQUEST,
  ENABLE_OUTLET_SUCCESS,
  ENABLE_OUTLET_FALIURE,
  DELETE_OUTLET_REQUEST,
  DELETE_OUTLET_SUCCESS,
  DELETE_OUTLET_FALIURE
} from './OutletConstants'

const intialstate = {
  is_loading : false,
  success_status : false,
  outlet_status : null,
  outlet: null,
  delete_outlet: null,
  get_error : ''
}

const OutletReducers = (state = intialstate,action) =>{
  switch(action.type){
    case GET_OUTLET_REQUEST : return{
      ...state,
      is_loading: true,
      success_status: false,
      outlet_status: null
    }
    case GET_OUTLET_SUCCESS : return{
      ...state,
      is_loading: false,
      success_status: true,
      outlet_status: action.payload
    }
    case GET_OUTLET_FALIURE : return{
      ...state,
      is_loading: false,
      success_status: false,
      get_error:action.payload
    }

    case ENABLE_OUTLET_REQUEST : return{
      ...state,
      is_loading: true,
      success_status: false,
      outlet: null
    }
    case ENABLE_OUTLET_SUCCESS : return{
      ...state,
      is_loading: false,
      success_status: true,
      outlet: action.payload
    }
    case ENABLE_OUTLET_FALIURE : return{
      ...state,
      is_loading: false,
      success_status: false,
      get_error:action.payload
    }

    case DELETE_OUTLET_REQUEST : return{
      ...state,
      is_loading: true,
      success_status: false,
      delete_outlet: null
    }
    case DELETE_OUTLET_SUCCESS : return{
      ...state,
      is_loading: false,
      success_status: true,
      delete_outlet: action.payload
    }
    case DELETE_OUTLET_FALIURE : return{
      ...state,
      is_loading: false,
      success_status: false,
      get_error:action.payload
    }
    default: return state
  }

}

export default OutletReducers
