import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGiftCertificate, addGiftCertificate, deleteGiftCertificate } from "../Redux/GiftCertificate/GiftCertificateActions";
import { uploadImage } from "../Redux/Gallery/GalleryActions";
import Switch from "react-switch";
import Loader from 'react-loader-spinner';
// import "./App.css"; // import your CSS

function GiftCertificates() {
    const giftCertificateData = useSelector(state => state.GiftCertificate);
    const galleryData = useSelector(state => state.Gallery);
    // store data access End
    const dispatch = useDispatch();
    const [forms, setForms] = useState([]);
    const [index, setIndex] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [paperCertificateStatus, setPaperCertificateStatus] = useState(false);
    const [giftCertificateStatus, setGiftCertificateStatus] = useState(false);
    const [removeStatus, setRemoveStatus] = useState(false);
    const [addStatus, setAddStatus] = useState(false);
    const [giftType, setGiftType] = useState('NONE');
    const [isLoaded, setLoaded] = useState(null);

    useEffect(() => {
      setLoaded(false);
      setRemoveStatus(false);
      setAddStatus(false);
      dispatch(getGiftCertificate());
    },[])

    const addForm = () => {
      setForms([...forms, { giftCardName: "", giftCardImageId: "", sellingPrice: "", price: "" }]);
    };
  
    const removeForm = () => {
      if (forms.length > 0) {
        setForms(forms.slice(0, -1));
      }
    };

    useMemo(() =>{
      if(galleryData && galleryData.upload_image && galleryData.upload_image.imageId) {
        setLoaded(true);
        setErrorMessage(null);
        document.getElementById(`giftCardImageId_${index}`).style.backgroundImage = `url(${galleryData.upload_image.endpoint})`;
        document.getElementById(`giftCardImageId_${index}`).style.backgroundSize = 'cover';
        forms[index].giftCardImageId = galleryData.upload_image.imageId;
      }
    },[galleryData])

    useMemo(() => {
      if(giftCertificateData.get_error) {
        setLoaded(true);
        setErrorMessage(giftCertificateData.get_error.object.error)
      }

      if(giftCertificateData.gift_certificate && giftCertificateData.gift_certificate.object) {
        
        if(giftCertificateData.gift_certificate.request_status && !removeStatus && !addStatus) {
          setLoaded(true);
          setErrorMessage(null);
          if(giftCertificateData.gift_certificate.object.GIFT_CERTIFICATE) {            
             setGiftType("GIFT_CERTIFICATE"); 
          } else if(giftCertificateData.gift_certificate.object.PAPER_GIFT_CERTIFICATE) {
             setGiftType("PAPER_GIFT_CERTIFICATE");
          }
          setGiftCertificateStatus(giftCertificateData.gift_certificate.object.GIFT_CERTIFICATE);
          setPaperCertificateStatus(giftCertificateData.gift_certificate.object.PAPER_GIFT_CERTIFICATE);
        }
      }

      if(giftCertificateData.remove_gift_certificate && giftCertificateData.remove_gift_certificate.request_status) {
        setLoaded(true);
        
        //console.log('giftType==>'+giftType);
        if(removeStatus && giftCertificateData.remove_gift_certificate.object) {
          setResponseMessage('Gift Certificate Removed!');
          setErrorMessage(null);
          if(giftType == "GIFT_CERTIFICATE") {
             setGiftCertificateStatus(false);
          }
          if(giftType == "PAPER_GIFT_CERTIFICATE") {
             setPaperCertificateStatus(false);
          }
        } else if(removeStatus) {
          setErrorMessage("Unabled to delete");
        }
      }

      if(giftCertificateData.add_gift_certificate && giftCertificateData.add_gift_certificate.request_status) {
        setLoaded(true);
        if(addStatus) {
          setErrorMessage(null);
          if(giftCertificateData.add_gift_certificate.request_status && !removeStatus) {
            setResponseMessage('Gift Certificate Added!');
            if(giftType == "GIFT_CERTIFICATE") {
              setGiftCertificateStatus(giftCertificateData.add_gift_certificate.object);
            }
            if(giftType == "PAPER_GIFT_CERTIFICATE") {
              setPaperCertificateStatus(giftCertificateData.add_gift_certificate.object);
            }
          }
        }
      }

    }, [giftCertificateData])
  
    const handleInputChange = (index, event) => {
      const { name, value } = event.target;
      const updatedForms = forms.map((form, i) => 
        i === index ? { ...form, [name]: value } : form
      );
      setForms(updatedForms);
      
    };

    const saveFormData = () => {
      
      // if(giftType == "PAPER_GIFT_CERTIFICATE"){
      //   forms.map((form, i) => {
      //     form.price = undefined;
      //   });
      // }
      var isSubmit = true;
      setErrorMessage(null);
      forms.map((form, i) => {
        form.price = undefined;
        if(form.giftCardName.trim() == '') {
          isSubmit = false;
          setErrorMessage('GiftCard Name is required!');
        }
        else if(form.giftCardImageId.trim() == '') {
          isSubmit = false;
          setErrorMessage('GiftCard Image is required!');
        }
        else if(form.sellingPrice.trim() == '') {
          isSubmit = false;
          setErrorMessage('selling Price is required!');
        }
      });
      if(isSubmit) {
        setLoaded(false);
        setRemoveStatus(false);
        setAddStatus(true);
        dispatch(addGiftCertificate(giftType, forms));
      }
    }

    const uploadFunction = (ind) => {
      let input = document.createElement('input');
      input.type = 'file';
      input.onchange = _ => {
        if (input.files[0]) {
          // Validate file type
          if (input.files[0].type !== 'image/jpeg') {
            setErrorMessage('Only JPG images are allowed.');
          } else {
             setLoaded(false);
             setIndex(ind);
             dispatch(uploadImage(input.files[0]));
          }
        }    
      };
      input.click(); 
    }

    const addCertificate = (giftCertificateStatus) => {
      if(giftCertificateStatus) {
        setLoaded(false);
        setRemoveStatus(true);
        setAddStatus(false);
        setGiftType("GIFT_CERTIFICATE");       
        dispatch(deleteGiftCertificate("GIFT_CERTIFICATE"));
      } else {
        setGiftCertificateStatus(!giftCertificateStatus);
      }
      setGiftType("GIFT_CERTIFICATE");
    }

    const addPaperCertificate = (paperCertificateStatus) => {
      if(paperCertificateStatus) {
        setLoaded(false);
        setRemoveStatus(true);
        setAddStatus(false);
        setGiftType("PAPER_GIFT_CERTIFICATE");
        dispatch(deleteGiftCertificate("PAPER_GIFT_CERTIFICATE"));
      } else {
        setPaperCertificateStatus(!paperCertificateStatus);
      }
      setGiftType("PAPER_GIFT_CERTIFICATE");
    }
  
    return (
     <div className="card">
      <div className="card-body">
      {isLoaded == false && <div style={{textAlign: 'center'}}>
                  <Loader
                    className="student-detail-loader"
                    type="ThreeDots"
                    color="#fea801"
                    height={90}
                    width={150}
                  />
                </div> }
                {responseMessage ?
          <p className="alert alert-success w-auto" role="alert">
            {responseMessage}
          </p> : <p></p>
        }          
                {errorMessage ?
      <p className="alert alert-danger w-auto" role="alert">
        {errorMessage}
      </p> : <p></p>
    }          
       <div className="row" style={{justifyContent: 'center'}}>
       

       <div className="">
            <label>Paper Gift Certificate</label>
                        
        <Switch
          onChange={(e) =>
              addPaperCertificate(paperCertificateStatus)
          }
          checked={paperCertificateStatus}
          uncheckedIcon={false}
          checkedIcon={true}                        
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"/>
        </div>
        </div>

        <div className="row" style={{justifyContent: 'center'}}>
        <div className=""> 
            <label>E- Gift Certificate</label>
                        
        <Switch
          onChange={(e) =>
              addCertificate(giftCertificateStatus)
          }
          checked={giftCertificateStatus}
          uncheckedIcon={false}
          checkedIcon={true}                        
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"/>
        </div>
        </div>
      

        {(paperCertificateStatus || giftCertificateStatus) && <div className="right-float mg-t-20 mg-l-10">
                  <button className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark mg-b-10" onClick={addForm}>+</button>
                  <br/>
                  <button className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark" onClick={removeForm}>-</button>
               </div>}
        <div className="mg-t-20">
        
        {(paperCertificateStatus || giftCertificateStatus) && <div className="row card-body mg-t-10">
               <div className="col-lg-3 col-12 form-group">
                  <label>Name</label>
               </div>

               <div className="col-lg-5 col-12 form-group">
                    <label>Gift Card Image</label>                    
               </div>

               <div className="col-lg-2 col-12 form-group">
                  <label>Selling Price</label>
               </div>
{/* 
               {giftCertificateStatus && <div className="col-lg-2 col-12 form-group">
                  <label>Benefit Price</label>
               </div>} */}
           </div>}
          {(paperCertificateStatus || giftCertificateStatus) && forms.map((form, index) => (
            <form key={index} className="dynamic-form">

           <div className="row card-body mg-t-10 mg-r-60">
               <div className="col-lg-3 col-12 form-group">
                    <input type="text" placeholder name="giftCardName" className="form-control" value={form.giftCardName} onChange={(e) => handleInputChange(index, e)} required />
               </div>

              <div className="col-lg-5 col-12 form-group mg-0">
              <div className="rcorners1" id={`giftCardImageId_${index}`} style={{background:  "#afb0ad"}} type="image">
                   <button type="button" onClick={(e) => uploadFunction(index)} style={{marginTop: '70px'}} className='btn-fill-lg bg-blue-dark btn-hover-yellow'>Upload</button>
                   <input type="hidden" name="giftCardImageId" value={form.giftCardImageId}/>
                </div>
              </div>

               {/* <div className="col-lg-5 col-12 form-group">
                    <textarea
                      placeholder="Enter description"
                      className="form-contrl"
                      name="description"
                      value={form.description}
                      onChange={(e) => handleInputChange(index, e)}
                      required
                     />
               </div> */}

               <div className="col-lg-2 col-12 form-group">
                    <input type="text" placeholder name="sellingPrice" className="form-control" value={form.sellingPrice} onChange={(e) => handleInputChange(index, e)} required />
               </div>

               {/* {giftCertificateStatus && <div className="col-lg-2 col-12 form-group">
                    <input type="text" placeholder name="price" className="form-control" value={form.price} onChange={(e) => handleInputChange(index, e)} required />
               </div>}       */}

           </div>       
            </form>
          ))}
          
        </div>
        {/* <pre className="output">{JSON.stringify(forms, null, 2)}</pre> */}
        {forms.length > 0 &&
        <div className="col-12 form-group mg-t-8">
           <button type="button" className="right-float btn-fill-lg btn-gradient-yellow btn-hover-bluedark" onClick={saveFormData}>Save</button>
        </div>   }
     
        </div>
     </div>
    );
}
  
export default GiftCertificates;