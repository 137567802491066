import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';
import { fetchEndpoint, checkAvailability, addSubDomain, resetSubDomain} from '../Redux/SubDomain/SubDomainActions';
import QrCodeComponent from './QrCodeComponent';
// import QrCodeComponent from './QrCodeComponent';


function SubDomain() {

  const [fieldsData,setFieldsData] = useState({
    value: ""
  })

  const [defaultData, setDefaultData] = useState({
    value: ""
  })

  const dispatch = useDispatch();
  const [show,setShow] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const submitHandler = (event) => {
      event.preventDefault()
      if(isActive){
        dispatch(resetSubDomain())
        dispatch(addSubDomain(fieldsData.value))
        setShow(true)
        setFieldsData(defaultData);
      }
  }

  const validateHandler = (event) => {
    if(isActive){
      dispatch(resetSubDomain())
      dispatch(checkAvailability(fieldsData.value))
      setShow(true)
    }
   // setFieldsData(defaultData);
  }

  const [responseMessage, setResponseMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const subDomainData = useSelector(state => state.SubDomain)

  useEffect(() => {
    dispatch(fetchEndpoint());
  },[])
 
  useMemo(() => {
 
      if (subDomainData.endpoint && subDomainData.endpoint.request_status == true) {
        setIsActive(false)
        const hostname = new URL(subDomainData.endpoint.object).hostname;
        fieldsData.value = hostname.split('.')[0];
      } else if (subDomainData.endpoint) {
        setIsActive(true)
        setFieldsData(defaultData)
      }

      if (subDomainData.add_subdomain && subDomainData.add_subdomain.request_status === true) {
        setResponseMessage("Sub domain Added Successfully!")
      } else if (subDomainData.add_subdomain_error && subDomainData.add_subdomain_error.object) {
        setErrorMessage(subDomainData.add_subdomain_error.object.error)
      }
      

      if (subDomainData.check_subdomain && subDomainData.check_subdomain.request_status === true) {
        //setResponseMessage("Sub domain Exist!")
      } else if (subDomainData.check_subdomain_error && subDomainData.check_subdomain_error.object) {
        setErrorMessage(subDomainData.check_subdomain_error.object.error)
      }
  
  }, [subDomainData])


 

  return (
    <>
      <div className="breadcrumbs-area">
        <h3>Order Online Link</h3>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Order Online Link</li>
        </ul>
      </div>
      {/* Breadcubs Area Start Here */}


      <div className="card-body mx-width-1000">

        <div className="heading-layout1">
          <div className="item-title">

          </div>

        </div>

     
    

        <div className="pad-5 bg-darkblue height-50">
          <ul className="nav nav-tabs justify-content-center" role="tablist">
            <li>
              <a href="#" className='nav-link mg-r-10 active show' aria-controls="billing" role="link" aria-expanded="true" aria-disabled="true">Sub Domain Creation</a>
            </li>
          </ul>
        </div>
        
        {responseMessage && show ?
      
      <p className="alert alert-success w-auto" role="alert" style={{marginTop:"1%"}}>
      {responseMessage}
      </p>: <p></p>
    }

    {errorMessage ?
      <p className="alert alert-danger w-auto" role="alert">
        {errorMessage}
      </p> : <p></p>
    }
       
        <div className="tab-content single-info-details mx-width-1000">
          <div role="tabpanel" className='tab-pane active show' id="billing">
         
          
                           
          <form onSubmit={(e) =>submitHandler(e)}>  
            <div className="row">
        
              <div className="col-12-xxxl col-lg-6 col-12 form-group mg-0">
                <label>Domain Name</label>

                <table className="width-100">
                            <tr>
                              
                              <td className="cl-center">
                              <input type="text" className="form-control" placeholder='Enter Domain Name' value={fieldsData.value} onChange={(e) =>setFieldsData({...fieldsData,value:e.target.value})} required />
                              </td>
                              <td className="cl-center">
                                 <label>diningtek.com</label>
                                 
                              </td>
                              </tr>
                </table>
              </div>
             
            </div>

            <div className='float-right mg-t-30 mg-l-10'>

               <button type="submit"  className={(isActive && !subDomainData.is_loading) ? 'btn-fill-lg bg-blue-dark btn-hover-yellow' : 'btn-fill-lg btn-hover-disable'}>Create</button>
            </div>

            <div className='float-right mg-t-30'>

              <button type="button" className={(isActive && !subDomainData.is_loading) ? 'btn-fill-lg bg-blue-dark btn-hover-yellow' : 'btn-fill-lg btn-hover-disable'} onClick={(e) => validateHandler()}>Validate</button>
            
            </div>
            
            
            </form>
          </div>    

           
         

        </div>
        <br/>        

        {subDomainData.endpoint && subDomainData.endpoint.object && <div className="App">
            <QrCodeComponent value={subDomainData.endpoint.object} />
           </div>}  

      </div>

    </>
  );
};

export default SubDomain;
