export const ADD_GIFT_CERTIFICATE_REQUEST = 'ADD_GIFT_CERTIFICATE_REQUEST'
export const ADD_GIFT_CERTIFICATE_SUCCESS = 'ADD_GIFT_CERTIFICATE_SUCCESS'
export const ADD_GIFT_CERTIFICATE_FALIURE = 'ADD_GIFT_CERTIFICATE_FALIURE'
export const DELETE_GIFT_CERTIFICATE_REQUEST = 'DELETE_GIFT_CERTIFICATE_REQUEST'
export const DELETE_GIFT_CERTIFICATE_SUCCESS = 'DELETE_GIFT_CERTIFICATE_SUCCESS'
export const DELETE_GIFT_CERTIFICATE_FALIURE = 'DELETE_GIFT_CERTIFICATE_FALIURE'
export const GET_GIFT_CERTIFICATE_REQUEST = 'GET_GIFT_CERTIFICATE_REQUEST'
export const GET_GIFT_CERTIFICATE_SUCCESS = 'GET_GIFT_CERTIFICATE_SUCCESS'
export const GET_GIFT_CERTIFICATE_FALIURE = 'GET_GIFT_CERTIFICATE_FALIURE'

