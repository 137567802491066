export const CATEGORY_REORDER_REQUEST = 'CATEGORY_REORDER_REQUEST'
export const CATEGORY_REORDER_SUCCESS = 'CATEGORY_REORDER_SUCCESS'
export const CATEGORY_REORDER_FALIURE = 'CATEGORY_REORDER_FALIURE'
export const PRODUCT_REORDER_REQUEST = 'PRODUCT_REORDER_REQUEST'
export const PRODUCT_REORDER_SUCCESS = 'PRODUCT_REORDER_SUCCESS'
export const PRODUCT_REORDER_FALIURE = 'PRODUCT_REORDER_FALIURE'
export const ADDON_GROUP_REORDER_REQUEST = 'ADDON_GROUP_REORDER_REQUEST'
export const ADDON_GROUP_REORDER_SUCCESS = 'ADDON_GROUP_REORDER_SUCCESS'
export const ADDON_GROUP_REORDER_FALIURE = 'ADDON_GROUP_REORDER_FALIURE'
export const ADDON_REORDER_REQUEST = 'ADDON_REORDER_REQUEST'
export const ADDON_REORDER_SUCCESS = 'ADDON_REORDER_SUCCESS'
export const ADDON_REORDER_FALIURE = 'ADDON_REORDER_FALIURE'