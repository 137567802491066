import {
  ENDPOINT_REQUEST,
  ENDPOINT_SUCCESS,
  ENDPOINT_FALIURE,
  CHECK_SUBDOMAIN_REQUEST,
  CHECK_SUBDOMAIN_SUCCESS,
  CHECK_SUBDOMAIN_FALIURE,
  ADD_SUBDOMAIN_REQUEST,
  ADD_SUBDOMAIN_SUCCESS,
  ADD_SUBDOMAIN_FALIURE
} from './SubDomainConstants'
import {config} from '../config'

export const endpointRequest = (endpoint_info) =>{
  return{
    type : ENDPOINT_REQUEST,
    payload : endpoint_info
  }
}

export const endpointSuccess = (endpoint_success) =>{
  return{
    type : ENDPOINT_SUCCESS,
    payload : endpoint_success
  }
}

export const endpointFaliure = (error) =>{
  return{
    type : ENDPOINT_FALIURE,
    payload : error
  }
}

export const checkSubdomainRequest = (check_subdomain) =>{
  return{
    type : CHECK_SUBDOMAIN_REQUEST,
    payload : check_subdomain
  }
}

export const checkSubdomainSuccess = (check_subdomain_success) =>{
  return{
    type : CHECK_SUBDOMAIN_SUCCESS,
    payload : check_subdomain_success
  }
}

export const checkSubdomainFaliure = (error) =>{
  return{
    type : CHECK_SUBDOMAIN_FALIURE,
    payload : error
  }
}


export const addSubdomainRequest = (add_subdomain) =>{
  return{
    type : ADD_SUBDOMAIN_REQUEST,
    payload : add_subdomain
  }
}

export const addSubdomainSuccess = (add_subdomain_success) =>{
  return{
    type : ADD_SUBDOMAIN_SUCCESS,
    payload : add_subdomain_success
  }
}

export const addSubdomainFaliure = (error) =>{
  return{
    type : ADD_SUBDOMAIN_FALIURE,
    payload : error
  }
}

export const fetchEndpoint = (endpoint) =>{
    return(dispatch) => {
      dispatch(endpointRequest(endpoint))
      const url =`${config.api_root}/api/v1/merchants/endpoint`;
      const request_option = {
      method: "GET",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          }
    }
    fetch(url, request_option)
     .then(response => response.json())
     .then(endpoint_res => {
        const endpoint_success = endpoint_res
        dispatch(endpointSuccess(endpoint_success))
    })
    .catch(error => {
       const errorMsg = error
       dispatch(endpointFaliure(errorMsg))
    })
    }
}

export const resetSubDomain = (rest_shut_info) =>{
  return(dispatch) => {
    dispatch(checkSubdomainRequest(rest_shut_info))
    dispatch(addSubdomainRequest(rest_shut_info))
  }
}

export const checkAvailability = (value) => {
    return(dispatch) => {
      dispatch(checkSubdomainRequest(value))
      const url =`${config.api_root}/api/v1/merchants/endpoint/availability`;
      const request_option = {
      method: "POST",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            fields : value
          })    
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(rest_shut_res =>{
      const api_response = rest_shut_res
      // if(rest_shut_success.success==='true'){
      //   let res = {
      //     success:rest_shut_success.success,
      //     status: rest_shut_info
      //   }
      //   dispatch(checkSubdomainSuccess(res))
      // } else {
        if(api_response.request_status == true){
          dispatch(checkSubdomainSuccess(api_response))
        } else {
          dispatch(checkSubdomainFaliure(api_response))
        }
      // }
      console.log(api_response)
    })
    .catch(error => {
      const errorMsg = error
      dispatch(checkSubdomainFaliure(errorMsg))
    })
    }
}

export const addSubDomain = (value) =>{
  return(dispatch) => {
        dispatch(addSubdomainRequest(value))
        const url =`${config.api_root}/api/v1/merchants/endpoint`;
        const request_option = {
        method: "PATCH",
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          fields : value
        })  
  }
  fetch(url, request_option)
   .then(response => response.json())
   .then(endpoint_res => {

      const api_response = endpoint_res;
        if(api_response.request_status == true){
          dispatch(addSubdomainSuccess(api_response))
        } else {
          dispatch(addSubdomainFaliure(api_response))
        }
  })
  .catch(error => {
     const errorMsg = error
     dispatch(addSubdomainFaliure(errorMsg))
  })
  }
}
