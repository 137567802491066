import {
  GET_GALLERY_REQUEST,
  GET_GALLERY_SUCCESS,
  GET_GALLERY_FALIURE,
  UPLOAD_REQUEST,
  UPLOAD_SUCCESS,
  UPLOAD_FALIURE
} from './GalleryConstants'
import {config} from '../config'

export const getGalleryRequest = (gallery_req) =>{
  return{
    type : GET_GALLERY_REQUEST,
    payload : gallery_req
  }
}

export const getGallerySuccess = (gallery_req_success) =>{
  return{
    type : GET_GALLERY_SUCCESS,
    payload : gallery_req_success
  }
}

export const getGalleryFaliure = (error) =>{
  return{
    type : GET_GALLERY_FALIURE,
    payload : error
  }
}

export const uploadRequest = (gallery_req) =>{
  return{
    type : UPLOAD_REQUEST,
    payload : gallery_req
  }
}

export const uploadSuccess = (gallery_req_success) =>{
  return{
    type : UPLOAD_SUCCESS,
    payload : gallery_req_success
  }
}

export const uploadFaliure = (error) =>{
  return{
    type : UPLOAD_FALIURE,
    payload : error
  }
}

export const fetchGalleries = () =>{
  return(dispatch) => {
    dispatch(getGalleryRequest(false))
    const url =`${config.api_root}/api/v1/merchants/galleries?w=360&h=240&type=BANNER&mid=${config.mid}`
    const request_option = {
       method: "GET",
       headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
        }
    }
    fetch(url, request_option)
     .then(response => response.json() )
     .then(res =>{
        dispatch(getGallerySuccess(res))
     })
    .catch(error => {
      const errorMsg = error
      dispatch(getGalleryFaliure(errorMsg))
    })
  }
}

export const uploadImage = (file) =>{
  
  return(dispatch) => {

    file
    .arrayBuffer()
    .then((buffer) => {
      dispatch(uploadRequest(false))
      const url =`${config.api_root}/api/v1/media/upload?user_id=${config.user_id}`
      const request_option = {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            "Content-Type": file.type,
        },
        body: buffer
      }
      fetch(url, request_option)
       .then(response => response.json() )
       .then(res =>{
          dispatch(uploadSuccess(res))
       })
      .catch(error => {
        const errorMsg = error
        dispatch(uploadFaliure(errorMsg))
      })
   });
  }
}
