import React, { useState, useEffect, useMemo } from 'react';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useSelector, useDispatch } from 'react-redux'
import { fetchAllCategories, fetchproducts, updateCategory, deleteCategory } from '../Redux/AllCategories/AllCategoriesActions';
import { categoriesReorder } from '../Redux/ReOrdering/ReOrderingActions';
import { Link, useHistory, useLocation } from "react-router-dom";
import { Button, Pagination } from 'react-bootstrap';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

function AllExpense() {
  // store data access start
  const allCategoriesData = useSelector(state => state.AllCategories)
  const reOrderingData = useSelector(state => state.ReOrdering)
  // store data access End
  const dispatch = useDispatch()  // for accessing the redux function

  // component all states define start
  const [allCategoriesList, setAllCategoriesList] = useState([])
  const [allCategoriesResult, setAllCategoriesResult] = useState([])
  const [allProductResult, setAllProductResult] = useState([]);
  const [categoryStatus, setCategoryStatus] = useState('ACTIVE')
  const [currentcatstaus, setCurrentcatstaus] = useState(true)
  const [allproductsloaded, setAllproductsloaded] = useState('')
  const [respmessage, setRespmessage] = useState('')
  const [selectedcatId, setSelectedcatId] = useState('')
  const history = useHistory();
  const [editCatMode, setEditCatMode] = useState(false)


  useEffect(() => {
    dispatch(fetchAllCategories(totalPages,currentPage))
  }, [dispatch])

  // add data of allAssignBook api into constant,hook start
  useMemo(() => {
    if (allCategoriesData && allCategoriesData.all_categories && allCategoriesData.all_categories.data) {
      
      setAllCategoriesList(allCategoriesData.all_categories.data);
      var categorylst = allCategoriesData.all_categories.data.filter(list => list.status == categoryStatus);
      setAllCategoriesResult(categorylst);
    }
  }, [allCategoriesData])


  useMemo(() => {
    if (allCategoriesData && allCategoriesData.all_products) {
      setAllproductsloaded(false);
      setAllProductResult(allCategoriesData.all_products.data);
      if (allCategoriesData.all_products_loaded) {
        setAllproductsloaded(true);
      }
    }
  }, [allCategoriesData]);

  useMemo(() => {
    if (reOrderingData && reOrderingData.category_reorder && reOrderingData.category_reorder.object) {
      setRespmessage("reorder successfully!");
      
    }
  }, [reOrderingData]);



const showProducts = (categoryId, categoryName) => {

// console.log(categoryId,categoryName);
  setAllProductResult([])
  dispatch(fetchproducts(categoryId));
  setSelectedcatId(categoryId);
}




  const hideProducts = () => {
    setAllProductResult([])
    setAllproductsloaded(false)
    setEditCatMode(false);
  }
  




 

  const editCat = (item) => {
    let category = {
      catID: item.categoryId,
      catName: item.categoryName,
      catDes: item.descriptions,
      catLongDes: item.longDescription,
      orderIndex: item.order,
      catStatus: item.status
    };
    return category;
  }



  const deleteCat = (groupId) => {  
    if(window.confirm("Are you sure want to delete?")){
      dispatch(deleteCategory(groupId));
    }
     
    }

  



  const onChangeValue = (e) => {
    setCategoryStatus(e.target.value);
    setCurrentcatstaus(!currentcatstaus)
    var categories = allCategoriesList.filter(list => list.status == e.target.value);
    setAllCategoriesResult(categories);
  }

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(allCategoriesResult);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);

    setAllCategoriesResult(reorderedItems);
    const categoryLst = reorderedItems.map(item => item.categoryId);
    console.log('categoryLst==>'+categoryLst)
    dispatch(categoriesReorder(categoryLst));
  };

const [singleCategory,setSingleCategory] = useState(false);

// tryy

// pagination
const [currentPage, setCurrentPage] = useState(0);
const [totalPages, setTotalPages] = useState(0);

const [data1, setData1] = useState();
const [pageSize, setPageSize] = useState(10);
// const allBook = useSelector(state => state.AllBook);
const [productData,setProductData] = useState('');

console.log(allCategoriesData);

useEffect(() => {
  if (data1 && data1.length > 0) {
  setTotalPages(Math.ceil(allCategoriesData.all_categories.total / pageSize));
  }
  }, [data1, pageSize]);

  useMemo(() =>{
    if(allCategoriesData && allCategoriesData.all_categories && allCategoriesData.all_categories.data){
      setProductData(allCategoriesData.all_categories.data)
      setData1(allCategoriesData.all_categories.data)   
    } else {
      setProductData('')
    }
   
  },[allCategoriesData])


const onPageChange = (currentPage) => {
  setCurrentPage(currentPage);
// dispatch(fetchAllProducts(totalPages,currentPage))
// dispatch(fetchAllAddons(totalPages,currentPage));
dispatch(fetchAllCategories(totalPages,currentPage))
};


const renderPagination = () => {
  let items = [];
  for (let i = 0; i < totalPages; i++) {
  items.push(
  <Pagination.Item
  key={i}
  active={i === currentPage}
  onClick={() => onPageChange(i)}
  >
  {i+1}
  </Pagination.Item>
  );
  }
  return (
  <Pagination style={{ overflowX: "auto" }}>
  <Pagination.Prev
  disabled={currentPage === 1}
  onClick={() => onPageChange(currentPage - 1)}
  />
  {items}
  <Pagination.Next
  disabled={currentPage === totalPages}
  onClick={() => onPageChange(currentPage + 1)}
  />
  </Pagination>
  );
  };






  return (
 <>

  <div className="breadcrumbs-area">
        <h3>Categories</h3>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>All Categories</li>
        </ul>
      </div>
<div className="catstatusdiv" onChange={(e) => onChangeValue(e)}>
        <input type="radio" value="ACTIVE" name="status" checked={currentcatstaus} /> Active Categories
        <input type="radio" value="INACTIVE" name="status" /> Inactive Categories
      </div>
      {/* Breadcubs Area End Here */}
      {/* Expanse Table Area Start Here */}
      <div className="row">
        <div className="col-12-xxxl col-12 col-md-12">
          <div className="card">
          {reOrderingData.is_loading ?
                          <div className='mg-l-40'>
                          <Loader
                            className = "cl-center"
                            type="MutatingDots"
                            color="#fea801"
                            height={150}
                            width={150}    
                          /></div>
               : reOrderingData.category_reorder && reOrderingData.category_reorder.object === true ? (<div className="success-greeting1">
                             <h2 className="addtimesuccess cl-center">{respmessage}</h2>                              
                          </div>) : reOrderingData.category_reorder && reOrderingData.category_reorder.object.error ? (<div className="success-greeting1">
                             <h2 className="addtimeerror cl-center">{reOrderingData.category_reorder.object.error}</h2>
                          
                           </div>): null}

            {allCategoriesData && allCategoriesData.all_categories_loading === true ?
              (<div className={editCatMode && editCatMode === true ? 'card-body hidden' : ''}>
                <div className="Student-Loader">
                  <Loader
                    className="student-detail-loader"
                    type="ThreeDots"
                    color="#fea801"
                    height={150}
                    width={150}

                  />
                </div>
              </div>) : (



                <div className={editCatMode && editCatMode === true ? 'table-responsive expenses-table-box hidden' : 'table-responsive expenses-table-box'}>





<DragDropContext onDragEnd={handleOnDragEnd}>
  <Droppable droppableId="allCategoriesResult">
    {(provided) => (
      <div {...provided.droppableProps} ref={provided.innerRef}>
        {allCategoriesResult && allCategoriesResult.length > 0 && 
          allCategoriesResult.map((category, index) => (
            <Draggable key={category.categoryId} draggableId={category.categoryId} index={index}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  
                >
                  <div className='card-body2' style={{ marginBottom: '15px', overflowX: 'auto' }}>
                        <div className='pad-10'>
                          <div className="float-left mg-t-5 shown">

                            {category.categoryId == selectedcatId && allproductsloaded && allProductResult ? <span onClick={e => hideProducts()}><i className='fas fa-caret-down'></i> {category.categoryName}</span>
                              : <span onClick={e => showProducts(category.categoryId, category.categoryName)}>  <Link style={{color:"black"}} to={{ 
                                pathname: "/singlecategory", 
                                state: editCat(category) 
                              }}><i className='fas fa-caret-right'></i> {category.categoryName} </Link></span>}
                              </div> 
                              <div className="float-right" style={{marginLeft:"20px"}}>                                
                              <Link className="btn-fill-lg text-light bg-blue-dark btn-hover-yellow change" size="sm" onClick={e=>deleteCat(category.categoryId)}>
                                 Delete Category
                               </Link>
                           {/* <Button className="btn-fill-lg bg-blue-dark btn-hover-yellow change" size='sm' onClick={e=>deleteCat(category.categoryId)}>
                            Delete Category
                           </Button>  */}
                           </div>
                              <div className="float-right">                                
                           
                               <Link className="btn-fill-lg text-light bg-blue-dark btn-hover-yellow change" size="sm" to={{ 
                                  pathname: "/edit_category", 
                                  state: editCat(category) 
                                }}>
                                Edit Category
                               </Link>
                               </div>

   
                             
                        </div>

                       
                   
                      </div>

                </div>
              )}
            </Draggable>
          ))
        }
        {provided.placeholder}
      </div>
    )}
  </Droppable>
</DragDropContext>


{/*<DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="allCategoriesResult">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
                  {allCategoriesResult && allCategoriesResult.length > 0 && allCategoriesResult.filter(list => list.status === categoryStatus).map((category, index) => {
                   if (category.status === categoryStatus) {
                      <Draggable key={category.categoryId} draggableId={category.categoryId} index={index}>
                {(provided) => (
                  <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={{
                    padding: '8px',
                    margin: '4px',
                    backgroundColor: 'lightgray',
                    borderRadius: '4px',
                    ...provided.draggableProps.style,
                  }}
                >
                      (
                      <div className='card-body2' style={{ marginBottom: '15px', overflowX: 'auto' }}>
                        <div className='pad-10'>
                          <div className="float-left mg-t-5 shown">

                            {category.categoryId == selectedcatId && allproductsloaded && allProductResult ? <span onClick={e => hideProducts()}><i className='fas fa-caret-down'></i> {category.categoryName}</span>
                              : <span onClick={e => showProducts(category.categoryId, category.categoryName)}>  <Link style={{color:"black"}} to={{ 
                                pathname: "/singlecategory", 
                                state: editCat(category) 
                              }}><i className='fas fa-caret-right'></i> {category.categoryName} </Link></span>}
                              </div> 
                              <div className="float-right" style={{marginLeft:"20px"}}>                                
                              <Link className="btn-fill-lg text-light bg-blue-dark btn-hover-yellow change" size="sm" onClick={e=>deleteCat(category.categoryId)}>
                                 Delete Category
                               </Link>
                           
                           </div>
                              <div className="float-right">                                
                           
                               <Link className="btn-fill-lg text-light bg-blue-dark btn-hover-yellow change" size="sm" to={{ 
                                  pathname: "/edit_category", 
                                  state: editCat(category) 
                                }}>
                                Edit Category
                               </Link>
                               </div>

   
                             
                        </div>

                       
                   
                      </div>
                      )
                      </div>
                    )}
              </Draggable>

                   }
                  }
                  )}
                  {provided.placeholder}
                </div>
               )}
              </Droppable>
              </DragDropContext>*/}

                </div>
                )}
          </div>
        </div>
      </div>

      {/* <div className='row'>
        <div className="col-12-xxxl col-12">     
         <div style={{ marginTop: '10px' }}>{renderPagination()}</div>  
           
            </div>
       
          </div> */}
      
      {/* Expanse Table Area End Here */}
    </>

  );
}

export default AllExpense;

