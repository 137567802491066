import {
  ADD_GIFT_CERTIFICATE_REQUEST,
  ADD_GIFT_CERTIFICATE_SUCCESS,
  ADD_GIFT_CERTIFICATE_FALIURE,
  DELETE_GIFT_CERTIFICATE_REQUEST,
  DELETE_GIFT_CERTIFICATE_SUCCESS,
  DELETE_GIFT_CERTIFICATE_FALIURE,
  GET_GIFT_CERTIFICATE_REQUEST,
  GET_GIFT_CERTIFICATE_SUCCESS,
  GET_GIFT_CERTIFICATE_FALIURE
} from './GiftCertificateConstants'

const intialstate = {
  is_loading : false,
  success_status : false,
  gift_certificate : null,
  add_gift_certificate : null,
  remove_gift_certificate : null,
  get_error : ''
}

const GiftCertificateReducers = (state = intialstate,action) =>{
  switch(action.type){
    case GET_GIFT_CERTIFICATE_REQUEST : return{
      ...state,
      is_loading: true,
      success_status: false,
      gift_certificate: null,
      get_error: null
    }
    case GET_GIFT_CERTIFICATE_SUCCESS : return{
      ...state,
      is_loading: false,
      success_status: true,
      gift_certificate: action.payload
    }
    case GET_GIFT_CERTIFICATE_FALIURE : return{
      ...state,
      is_loading: false,
      success_status: false,
      get_error:action.payload
    }
    case ADD_GIFT_CERTIFICATE_REQUEST : return{
      ...state,
      is_loading: true,
      success_status: false,
      add_gift_certificate: null,
      get_error: null
    }
    case ADD_GIFT_CERTIFICATE_SUCCESS : return{
      ...state,
      is_loading: false,
      success_status: true,
      add_gift_certificate: action.payload
    }
    case ADD_GIFT_CERTIFICATE_FALIURE : return{
      ...state,
      is_loading: false,
      success_status: false,
      get_error:action.payload
    }
    case DELETE_GIFT_CERTIFICATE_REQUEST : return{
      ...state,
      is_loading: true,
      success_status: false,
      remove_gift_certificate: null,
      get_error: null
    }
    case DELETE_GIFT_CERTIFICATE_SUCCESS : return{
      ...state,
      is_loading: false,
      success_status: true,
      remove_gift_certificate: action.payload
    }
    case DELETE_GIFT_CERTIFICATE_FALIURE : return{
      ...state,
      is_loading: false,
      success_status: false,
      get_error:action.payload
    }
    default: return state
  }

}

export default GiftCertificateReducers
