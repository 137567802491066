import {
  ENDPOINT_REQUEST,
  ENDPOINT_SUCCESS,
  ENDPOINT_FALIURE,
  CHECK_SUBDOMAIN_REQUEST,
  CHECK_SUBDOMAIN_SUCCESS,
  CHECK_SUBDOMAIN_FALIURE,
  ADD_SUBDOMAIN_REQUEST,
  ADD_SUBDOMAIN_SUCCESS,
  ADD_SUBDOMAIN_FALIURE
} from './SubDomainConstants'

const intialstate = {
  is_loading : false,
  endpoint : null,
  endpoint_error : '',
  endpoint_success : false,
  check_subdomain : null,
  check_subdomain_error : '',
  check_subdomain_success : false,
  add_subdomain : null,
  add_subdomain_error : '',
  add_subdomain_success : false
}

const SubDomainReducers = (state = intialstate,action) =>{
  switch(action.type){
    case ENDPOINT_REQUEST : return{
      ...state,
      is_loading:true,
      endpoint: null,
      endpoint_error: null
    }
    case ENDPOINT_SUCCESS : return{
      ...state,
      is_loading:false,
      endpoint_success:true,
      endpoint:action.payload
    }
    case ENDPOINT_FALIURE : return{
      ...state,
      is_loading:false,
      endpoint_error:action.payload
    }
    case CHECK_SUBDOMAIN_REQUEST : return{
      ...state,
      is_loading:true,
      check_subdomain: null,
      check_subdomain_error: null
    }
    case CHECK_SUBDOMAIN_SUCCESS : return{
      ...state,
      is_loading:false,
      check_subdomain_success:true,
      check_subdomain:action.payload
    }
    case CHECK_SUBDOMAIN_FALIURE : return{
      ...state,
      is_loading:false,
      check_subdomain_error:action.payload
    }
    case ADD_SUBDOMAIN_REQUEST : return{
      ...state,
      is_loading:true,
      add_subdomain: null,
      add_subdomain_error: null
    }
    case ADD_SUBDOMAIN_SUCCESS : return{
      ...state,
      is_loading:false,
      add_subdomain_success:true,
      add_subdomain:action.payload
    }
    case ADD_SUBDOMAIN_FALIURE : return{
      ...state,
      is_loading:false,
      add_subdomain_error:action.payload
    }
    default: return state
  }

}

export default SubDomainReducers
