import {
  CATEGORY_REORDER_REQUEST,
  CATEGORY_REORDER_SUCCESS,
  CATEGORY_REORDER_FALIURE,
  PRODUCT_REORDER_REQUEST,
  PRODUCT_REORDER_SUCCESS,
  PRODUCT_REORDER_FALIURE,
  ADDON_GROUP_REORDER_REQUEST,
  ADDON_GROUP_REORDER_SUCCESS,
  ADDON_GROUP_REORDER_FALIURE,
  ADDON_REORDER_REQUEST,
  ADDON_REORDER_SUCCESS,
  ADDON_REORDER_FALIURE
} from './ReOrderingConstants'

const intialstate = {
  is_loading : false,
  category_success_status : false,
  product_success_status : false,
  addon_group_success_status : false,
  addon_success_status : false,
  success_status : false,
  category_reorder : null,
  product_reorder : null,
  addon_group_reorder : null,
  addon_reorder : null,
  category_error : '',
  product_error : '',
  addon_group_error : '',
  addon_error : ''
}

const ReOrderingReducers = (state = intialstate,action) =>{
  switch(action.type){
    case CATEGORY_REORDER_REQUEST : return{
      ...state,
      is_loading: true,
      category_success_status: false,
      category_reorder: null
    }
    case CATEGORY_REORDER_SUCCESS : return{
      ...state,
      is_loading: false,
      category_success_status: true,
      category_reorder: action.payload
    }
    case CATEGORY_REORDER_FALIURE : return{
      ...state,
      is_loading: false,
      category_success_status: false,
      error:action.payload
    }
    case PRODUCT_REORDER_REQUEST : return{
      ...state,
      is_loading: true,
      product_success_status: false,
      product_reorder: null
    }
    case PRODUCT_REORDER_SUCCESS : return{
      ...state,
      is_loading: false,
      product_success_status: true,
      product_reorder: action.payload
    }
    case PRODUCT_REORDER_FALIURE : return{
      ...state,
      is_loading: false,
      product_success_status: false,
      error:action.payload
    }

    case ADDON_GROUP_REORDER_REQUEST : return{
      ...state,
      is_loading: true,
      addon_group_success_status: false,
      addon_group_reorder: null
    }
    case ADDON_GROUP_REORDER_SUCCESS : return{
      ...state,
      is_loading: false,
      addon_group_success_status: true,
      addon_group_reorder: action.payload
    }
    case ADDON_GROUP_REORDER_FALIURE : return{
      ...state,
      is_loading: false,
      addon_group_success_status: false,
      error:action.payload
    }
    
    case ADDON_REORDER_REQUEST : return{
      ...state,
      is_loading: true,
      addon_success_status: false,
      addon_reorder: null
    }
    case ADDON_REORDER_SUCCESS : return{
      ...state,
      is_loading: false,
      addon_success_status: true,
      addon_reorder: action.payload
    }
    case ADDON_REORDER_FALIURE : return{
      ...state,
      is_loading: false,
      addon_success_status: false,
      error:action.payload
    }

    default: return state
  }

}

export default ReOrderingReducers
