import React from 'react';
import QRCode, { QRCodeCanvas } from 'qrcode.react';

const QrCodeComponent = ({ value }) => {
  return (
    <div>
      <QRCodeCanvas
        value={value}            // Text or URL for the QR code
        size={200}               // Size of the QR code
        bgColor="#ffffff"        // Background color
        fgColor="#000000"        // Foreground color
        level="H"                // Error correction level ('L', 'M', 'Q', 'H')
      />
      <p>Scan this QR Code</p>
    </div>
  );
};

export default QrCodeComponent;
