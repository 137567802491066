import {
  CATEGORY_REORDER_REQUEST,
  CATEGORY_REORDER_SUCCESS,
  CATEGORY_REORDER_FALIURE,
  PRODUCT_REORDER_REQUEST,
  PRODUCT_REORDER_SUCCESS,
  PRODUCT_REORDER_FALIURE,
  ADDON_GROUP_REORDER_REQUEST,
  ADDON_GROUP_REORDER_SUCCESS,
  ADDON_GROUP_REORDER_FALIURE,
  ADDON_REORDER_REQUEST,
  ADDON_REORDER_SUCCESS,
  ADDON_REORDER_FALIURE
} from './ReOrderingConstants'
import {config} from '../config'

export const categoryReorderRequest = (category_reorder_req) =>{
  return{
    type : CATEGORY_REORDER_REQUEST,
    payload : category_reorder_req
  }
}

export const categoryReorderSuccess = (category_reorder) =>{
  return{
    type : CATEGORY_REORDER_SUCCESS,
    payload : category_reorder
  }
}

export const categoryReorderFaliure = (error) =>{
  return{
    type : CATEGORY_REORDER_FALIURE,
    payload : error
  }
}

export const productReorderRequest = (product_reorder_req) =>{
  return{
    type : PRODUCT_REORDER_REQUEST,
    payload : product_reorder_req
  }
}

export const productReorderSuccess = (product_reorder) =>{
  return{
    type : PRODUCT_REORDER_SUCCESS,
    payload : product_reorder
  }
}

export const productReorderFaliure = (error) =>{
  return{
    type : PRODUCT_REORDER_FALIURE,
    payload : error
  }
}

export const addonGroupReorderRequest = (addonGroup_reorder_req) =>{
  return{
    type : ADDON_GROUP_REORDER_REQUEST,
    payload : addonGroup_reorder_req
  }
}

export const addonGroupReorderSuccess = (addonGroup_reorder) =>{
  return{
    type : ADDON_GROUP_REORDER_SUCCESS,
    payload : addonGroup_reorder
  }
}

export const addonGroupReorderFaliure = (error) =>{
  return{
    type : ADDON_GROUP_REORDER_FALIURE,
    payload : error
  }
}

export const addonReorderRequest = (addon_reorder_req) =>{
  return{
    type : ADDON_REORDER_REQUEST,
    payload : addon_reorder_req
  }
}

export const addonReorderSuccess = (addon_reorder) =>{
  return{
    type : ADDON_REORDER_SUCCESS,
    payload : addon_reorder
  }
}

export const addonReorderFaliure = (error) =>{
  return{
    type : ADDON_REORDER_FALIURE,
    payload : error
  }
}

export const categoriesReorder = (categories) =>{
  return(dispatch) => {
    dispatch(categoryReorderRequest(false))
    const url =`${config.api_root}/api/v1/merchants/product/categories/reorder`
    const request_option = {
       method: "PATCH",
       headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
       },
       body: JSON.stringify({
          user_id : config.user_id,
          fields : categories,
       }) 
    }
    fetch(url, request_option)
     .then(response => response.json() )
     .then(res =>{
        dispatch(categoryReorderSuccess(res))
     })
    .catch(error => {
      const errorMsg = error
      dispatch(categoryReorderFaliure(errorMsg))
    })
  }
}


export const productsReorder = (products) =>{
  return(dispatch) => {
    dispatch(productReorderRequest(false))
    const url =`${config.api_root}/api/v1/merchants/product/reorder`
    const request_option = {
       method: "PATCH",
       headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
       },
       body: JSON.stringify({
          user_id : config.user_id,
          fields : products,
       }) 
    }
    fetch(url, request_option)
     .then(response => response.json() )
     .then(res =>{
        dispatch(productReorderSuccess(res))
     })
    .catch(error => {
      const errorMsg = error
      dispatch(productReorderFaliure(errorMsg))
    })
  }
}

export const addonGroupsReorder = (products) =>{
  return(dispatch) => {
    dispatch(addonGroupReorderRequest(false))
    const url =`${config.api_root}/api/v1/merchants/product/addon_groups/reorder`
    const request_option = {
       method: "PATCH",
       headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
       },
       body: JSON.stringify({
          user_id : config.user_id,
          fields : products,
       }) 
    }
    fetch(url, request_option)
     .then(response => response.json() )
     .then(res =>{
        dispatch(addonGroupReorderSuccess(res))
     })
    .catch(error => {
      const errorMsg = error
      dispatch(addonGroupReorderFaliure(errorMsg))
    })
  }
}

export const addonsReorder = (addons) =>{
  return(dispatch) => {
    dispatch(addonReorderRequest(false))
    const url =`${config.api_root}/api/v1/merchants/product/addons/reorder`
    const request_option = {
       method: "PATCH",
       headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
       },
       body: JSON.stringify({
          user_id : config.user_id,
          fields : addons,
       }) 
    }
    fetch(url, request_option)
     .then(response => response.json() )
     .then(res =>{
        dispatch(addonReorderSuccess(res))
     })
    .catch(error => {
      const errorMsg = error
      dispatch(addonReorderFaliure(errorMsg))
    })
  }
}